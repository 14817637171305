import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateUser } from "../redux/actions/setUserActions";
import { showAlert } from "../redux/actions/setAppActions";
import { Loader } from "./Loader";
import { Layout } from "./Layout";
import { Input } from "./forms/Input";

const formFields = {
    surname: "Фамилия",
    name: "Имя",
};

export const PersonalData = ({ callback }) => {
    const dispatch = useDispatch();
    const [key, setKey] = useState("");
    const { user } = useSelector((state) => state.auth);
    const { loading } = useSelector((state) => state.app);
    const { role } = useSelector((state) => state.auth);
    const [form, setForm] = useState({});
    const [incorrectFields, setIncorrectFields] = useState([]);

    useEffect(() => {
        setForm({
            sex: user.sex,
            name: user.name,
            surname: user.surname,
            patronymic: user.patronymic,
            birthday: user.birthday,
            email: user.email,
            phone: user.phone,
            institution: user.institution,
            emailNew: user.emailNew,
            telegram: user.telegram,
            schedule: user.schedule,
        });
    }, [user]);

    const formatBirthday = useCallback((date) => {
        const newDate = new Date(date);
        return newDate.toLocaleDateString();
    }, []);

    const changeHandler = (event) => {
        const { name, value } = event.target;

        if (incorrectFields.includes(name)) {
            setIncorrectFields(incorrectFields.filter((item) => item !== name));
        }

        if (["name", "surname"].includes(name) && value.length > 20) {
            return;
        }

        setForm({ ...form, [name]: value });
    };

    const saveUserHandler = () => {
        const errorFields = [];
        const forbiddenSymbols = /[%;="*?#&()_~]/gi;
        if (
            form.surname.length < 2 ||
            form.surname.length > 20 ||
            forbiddenSymbols.test(form.surname)
        )
            errorFields.push("surname");
        if (form.name.length < 2 || form.name.length > 20 || forbiddenSymbols.test(form.name))
            errorFields.push("name");

        if (!errorFields.length) {
            dispatch(updateUser(form));
        } else {
            const incorrectFieldsMessage = errorFields.map((item) => formFields[item]).join("\n");
            setIncorrectFields(errorFields);
            dispatch(
                showAlert({
                    type: "error",
                    text: `Некорректно заполнены поля:\n\n${incorrectFieldsMessage}`,
                })
            );
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={!key ? () => callback() : () => setKey("")}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    ></button>
                </header>
                <div className="page__content">
                    {!key && <h3>Персональные данные</h3>}
                    {key === "sex" && <h3>Пол</h3>}
                    {key === "name" && <h3>ФИО</h3>}
                    {key === "birthday" && <h3>Дата рождения</h3>}

                    {key === "sex" && (
                        <div className="auth-page__input-group auth-page__input-group_row">
                            <div className="input-field">
                                <input
                                    id="m"
                                    type="radio"
                                    checked={form.sex === "1"}
                                    name="sex"
                                    value="1"
                                    onChange={changeHandler}
                                />
                                <label htmlFor="m">Мужской</label>
                            </div>
                            <div className="input-field">
                                <input
                                    id="f"
                                    type="radio"
                                    checked={form.sex === "2"}
                                    name="sex"
                                    value="2"
                                    onChange={changeHandler}
                                />
                                <label htmlFor="f">Женский</label>
                            </div>
                        </div>
                    )}

                    {key === "name" && (
                        <div className="auth-page__input-group">
                            <Input
                                id="surname"
                                name="surname"
                                placeholder="Фамилия"
                                value={form.surname}
                                onChange={changeHandler}
                                isError={incorrectFields.includes("surname")}
                            />
                            <Input
                                id="name"
                                name="name"
                                placeholder="Имя"
                                value={form.name}
                                onChange={changeHandler}
                                isError={incorrectFields.includes("name")}
                            />
                            <Input
                                id="patronymic"
                                name="patronymic"
                                placeholder="Отчество"
                                value={form.patronymic}
                                onChange={changeHandler}
                            />
                        </div>
                    )}

                    {key === "birthday" && (
                        <div className="auth-page__input-group">
                            <div className="input-field">
                                <input
                                    id="birthday"
                                    type="date"
                                    name="birthday"
                                    onChange={changeHandler}
                                    value={form.birthday}
                                />
                                <label htmlFor="birthday">Дата рождения</label>
                            </div>
                        </div>
                    )}
                    {key === "emailNew" && (
                        <div className="auth-page__input-group">
                            <h3 style={{ textAlign: "left" }}>Почта</h3>
                            <div className="input-field">
                                <input
                                    placeholder="Почта"
                                    id="emailNew"
                                    type="text"
                                    name="emailNew"
                                    onChange={changeHandler}
                                    value={form.emailNew}
                                />
                                <label htmlFor="email">Почта</label>
                            </div>
                        </div>
                    )}
                    {key === "telegram" && (
                        <div className="auth-page__input-group">
                            <h3 style={{ textAlign: "left" }}>Никнейм TG</h3>
                            <div className="input-field">
                                <input
                                    placeholder="Никнейм TG"
                                    id="telegram"
                                    type="text"
                                    name="telegram"
                                    onChange={changeHandler}
                                    value={form.telegram}
                                />
                                <label htmlFor="email">Никнейм TG</label>
                            </div>
                        </div>
                    )}
                    {!key && (
                        <>
                            <ul className="settings-details">
                                <li
                                    onClick={() => setKey("sex")}
                                    className="settings-details__item"
                                >
                                    <p>Пол</p>
                                    {!user.sex && <p>Пол не указан</p>}
                                    {!!user.sex && user.sex === "1" && <p>Мужской</p>}
                                    {!!user.sex && user.sex === "2" && <p>Женский</p>}
                                </li>
                                <li
                                    onClick={() => setKey("name")}
                                    className="settings-details__item"
                                >
                                    <p>ФИО</p>
                                    <p>
                                        {user.surname} {user.name} {user.patronymic}
                                    </p>
                                </li>
                                <li
                                    onClick={() => setKey("birthday")}
                                    className="settings-details__item"
                                >
                                    <p>Дата рождения</p>
                                    <p>{user.birthday && formatBirthday(user.birthday)}</p>
                                </li>
                                {role === "psych" ? (
                                    <>
                                        {/*<li className="settings-details__item">*/}
                                        {/*    <p>Учреждение</p>*/}
                                        {/*    <p>{user.institution}</p>*/}
                                        {/*</li>*/}
                                        <li className="settings-details__item">
                                            <p>Телефон</p>
                                            <p>{user.phone}</p>
                                        </li>
                                        <li className="settings-details__item">
                                            <p>Почта</p>
                                            <p>{user.email}</p>
                                        </li>
                                    </>
                                ) : (
                                    ""
                                )}
                                {role === "pupil" ? (
                                    <>
                                        {/*<li*/}
                                        {/*    onClick={() => setKey("emailNew")}*/}
                                        {/*    className="settings-details__item"*/}
                                        {/*>*/}
                                        {/*    <p>Почта</p>*/}
                                        {/*    <p>{user.emailNew}</p>*/}
                                        {/*</li>*/}
                                        <li
                                            onClick={() => setKey("telegram")}
                                            className="settings-details__item"
                                        >
                                            <p>Никнейм TG</p>
                                            <p>{user.telegram}</p>
                                        </li>
                                    </>
                                ) : (
                                    ""
                                )}
                            </ul>
                            {role === "psych" ? (
                                <p style={{ marginTop: 20, opacity: 0.5 }}>
                                    Если вам необходимо изменить один из параметров - напишите нам
                                    об этом по почте school@testu.online и укажите текущий логин и
                                    ваше ФИО
                                </p>
                            ) : (
                                ""
                            )}

                            {role === "pupil" ? (
                                <p style={{ marginTop: 20, opacity: 0.5 }}>
                                    Если вам необходимо изменить один из параметров - скажите об
                                    этом вашему педагогу-психологу и укажите текущий логин и ваше
                                    ФИО
                                </p>
                            ) : (
                                ""
                            )}
                        </>
                    )}
                </div>
                {!!key && (
                    <button onClick={saveUserHandler} className="main-btn">
                        Сохранить
                    </button>
                )}
            </div>
        </Layout>
    );
};
