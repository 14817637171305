export const problemsList = [
    {
        title: "Тест эмоционального интеллекта Холла",
    },
    {
        title: "Тест Айзенка",
    },
    {
        title: "Методика Акцентуации характера и темперамента личности",
    },
    {
        title: "Шкала депрессии Бека",
    },
];

export const problems = [
    {
        title: "Тест эмоционального интеллекта Холла",
        description: "",
        methods: [
            {
                id: "64306e9e72dbb9ff0fc818e2",
                name: "Тест эмоционального интеллекта Холла. Н.Холл",
                type: "Тест",
            },
        ],
    },
    {
        title: "Тест Айзенка",
        description: "",
        methods: [
            {
                id: "641ec41772dbb9ff0fc7bf1f",
                name: "Тест Айзенка. Г. Айзенк",
                type: "Тест",
            },
        ],
    },
    {
        title: "Методика Акцентуации характера и темперамента личности",
        description: "",
        methods: [
            {
                id: "6425725b72dbb9ff0fc7f101",
                name: "Методика Акцентуации характера и темперамента личности. Г. Шмишек, К. Леонгард",
                type: "Тест",
            },
        ],
    },
    {
        title: "Шкала депрессии Бека",
        description: "",
        methods: [
            {
                id: "64307b7372dbb9ff0fc824e0",
                name: "Шкала депрессии Бека",
                type: "Тест",
            },
        ],
    },
];
