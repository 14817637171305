import React, { memo, useState } from "react";
import { Link } from "react-router-dom";

import userImg from "static/img/user.svg";
import heroGirl from "static/img/landing/hero-girl.png";
import heroBlockImg1 from "static/img/landing/hero-block-1.svg";
import heroBlockImg2 from "static/img/landing/hero-block-2.svg";
import heroBlockImg3 from "static/img/landing/hero-block-3.svg";
import arrowRight from "static/img/landing/arrow-right.svg";

export const LandingHero = memo(({ user, showPopupHandler }) => {
    const [isShowLoginButtons, setIsShowLoginButtons] = useState(false);

    const showLoginButtonsHandler = () => {
        setIsShowLoginButtons((prevState) => !prevState);
    };

    return (
        <section className="landing__section">
            <div className="landing__hero">
                <h1 className="landing__section-title landing__hero-title">
                    Платформа автоматизации психодиагностики студентов
                </h1>

                <p className="landing__hero-subtitle">
                    Удобная Web платформа для коммуникации между психологом и студентом, в том числе
                    для проведения психодиагностических тестирований
                </p>

                <div className="landing__hero-buttons">
                    {user ? (
                        <Link
                            to="/dashboard"
                            className="landing__button landing__button-profile landing__hero-buttons-profile"
                        >
                            <img src={userImg} alt="user" /> Профиль
                        </Link>
                    ) : (
                        <div className="landing__hero-buttons-auth">
                            <button
                                onClick={showPopupHandler}
                                className="landing__button landing__button-secondary"
                            >
                                Хочу внедрить
                            </button>
                            <div className="landing__button-login-wrapper">
                                <button
                                    onClick={showLoginButtonsHandler}
                                    className="landing__button landing__button-login"
                                >
                                    <img src={userImg} alt="user" /> Войти
                                </button>
                                {isShowLoginButtons && (
                                    <div className="landing__hero-buttons-login">
                                        <Link to="/psych/login">
                                            Вход для психолога <img src={arrowRight} alt="" />
                                        </Link>

                                        <Link to="/student/login">
                                            Вход для студента <img src={arrowRight} alt="" />
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className="landing__hero-image-small">
                    <img src={heroGirl} alt="" />
                </div>

                <div className="landing__section-block-dark landing__hero-blocks">
                    <div className="landing__hero-blocks-text">
                        <div className="landing__hero-block">
                            <div className="landing__hero-block-icon">
                                <img src={heroBlockImg1} alt="" />
                            </div>
                            <p>
                                Экономия времени на проведение анкетирования студентов и выявления
                                поведенческих особенностей
                            </p>
                        </div>
                        <div className="landing__hero-block">
                            <div className="landing__hero-block-icon">
                                <img src={heroBlockImg2} alt="" />
                            </div>
                            <p>
                                Для пользования решением необходим только мобильный телефон и
                                интернет
                            </p>
                        </div>
                        <div className="landing__hero-block">
                            <div className="landing__hero-block-icon">
                                <img src={heroBlockImg3} alt="" />
                            </div>
                            <p>
                                Удобная инфографика результатов тестирования по каждому студенту и
                                университета в целом
                            </p>
                        </div>
                    </div>
                    <div className="landing__hero-blocks-img">
                        <img src={heroGirl} alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
});
