import React, { memo, useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

import userImg from "static/img/user.svg";
import arrowRight from "static/img/landing/arrow-right.svg";

export const LandingMobileMenu = memo(({ user, hideMobileMenu, showPopup }) => {
    const [isShowLoginButtons, setIsShowLoginButtons] = useState(false);

    const showLoginButtonsHandler = () => {
        setIsShowLoginButtons((prevState) => !prevState);
    };

    useEffect(() => {
        document.body.classList.add("noscroll");

        return () => {
            document.body.classList.remove("noscroll");
        };
    }, []);

    const showPopupHandler = () => {
        hideMobileMenu();
        showPopup();
    };

    return (
        <div className="landing__mobile-menu">
            <nav className="landing__mobile-menu-nav">
                <HashLink onClick={hideMobileMenu} to="#landing-about">
                    О продукте
                </HashLink>
                <HashLink onClick={hideMobileMenu} to="#landing-why">
                    Зачем наше решение
                </HashLink>
                <HashLink onClick={hideMobileMenu} to="#landing-documents">
                    Документы
                </HashLink>
                <HashLink onClick={hideMobileMenu} to="#landing-methodology">
                    Методология
                </HashLink>
                <HashLink onClick={hideMobileMenu} to="#landing-contacts">
                    Контакты
                </HashLink>
            </nav>

            <div className="landing__mobile-menu-auth">
                {user ? (
                    <Link to="/dashboard" className="landing__button landing__button-profile">
                        <img src={userImg} alt="user" /> Профиль
                    </Link>
                ) : (
                    <div className="landing__mobile-menu-buttons">
                        <button
                            onClick={showPopupHandler}
                            className="landing__button landing__button-secondary"
                        >
                            Хочу внедрить
                        </button>
                        <div className="landing__mobile-menu-buttons-enter">
                            <button
                                onClick={showLoginButtonsHandler}
                                className="landing__button landing__button-login"
                            >
                                <img src={userImg} alt="user" /> Войти
                            </button>

                            {isShowLoginButtons && (
                                <div className="landing__mobile-menu-buttons-login">
                                    <Link to="/psych/login">
                                        Вход для психолога <img src={arrowRight} alt="" />
                                    </Link>

                                    <Link to="/student/login">
                                        Вход для студента <img src={arrowRight} alt="" />
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});
