import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { BackButton } from "components/BackButton";
import { Range } from "components/Range";
import { Layout } from "components/Layout";
import { declinations } from "helpers/declinations";

/**
 * @api {get} /statistic/schools/:id статистика классов школ
 * @apiGroup statistic
 * @apiName статистика классов школ
 *
 * @apiParam {String} id ID школы
 *
 * @apiDescription <p>Страница статистики классов выбранной школы</p>
 * <p>Компонент <code>StatisticClasses.js</code> <a href="../client/src/pages/statistic/StatisticClasses.js">ссылка на файл</a></p>
 */

export const StatisticClasses = () => {
    const schoolId = useParams().id;
    const [school, setSchool] = useState(null);
    const [classes, setClasses] = useState([]);

    useEffect(() => {
        if (schoolId) {
            (async () => {
                const { data } = await axios.get(`statistic/schools/${schoolId}`);
                setClasses(data.classes);
                setSchool(data.school);
            })();
        }
    }, [schoolId]);

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">{school || "Статистика"}</p>
                </header>
                <div>
                    {classes.map((item) => (
                        <Link
                            key={item._id}
                            to={`/statistic/groups/${item._id}`}
                            className="statistic-condition"
                        >
                            <p className="statistic-condition__title">{item.name}</p>

                            <Range
                                total={item.total}
                                norm={item.positively}
                                // notNorm={item.negatively}
                                notNorm={item.total - item.positively}
                            />

                            <div className="statistic-condition__footer">
                                <span className="statistic-condition__indicator green">
                                    {item.positively}
                                </span>
                                {/*<span className="statistic-condition__indicator red">*/}
                                {/*    {item.negatively}*/}
                                {/*</span>*/}
                                <span className="statistic-condition__indicator blue">
                                    {/*{item.total - item.positively - item.negatively}*/}
                                    {item.total - item.positively}
                                </span>
                                <p className="statistic-condition__members">
                                    {item.total} {declinations(item.total, "test")}
                                </p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </Layout>
    );
};
