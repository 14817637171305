import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { LandingHeader } from "components/landing/LandingHeader";
import { LandingHero } from "components/landing/LandingHero";
import { LandingProblems } from "components/landing/LandingProblems";
import { LandingAbout } from "components/landing/LandingAbout";
import { LandingPopup } from "components/landing/LandingPopup";
import { LandingDocuments } from "components/landing/LandingDocuments";
import { LandingWhy } from "components/landing/LandingWhy";
import { LandingMethodology } from "components/landing/LandingMethodology";
import { LandingOurMethodologists } from "components/landing/LandingOurMethodologists";
import { LandingCompany } from "components/landing/LandingCompany";
import { LandingContacts } from "components/landing/LandingContacts";
import { LandingFooter } from "components/landing/LandingFooter";
import { LandingMobileMenu } from "components/landing/LandingMobileMenu";
import "../static/scss/landing.scss";

/**
 * @api {get} / лендинг
 * @apiGroup main
 * @apiName лендинг
 * @apiDescription <p>Главная страница / лендинг</p>
 * <p>Компонент <code>LandingPage.js</code> <a href="../client/src/pages/LandingPage.js">ссылка на файл</a></p>
 */

export const LandingPage = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get("auth/user");
                dispatch({ type: "AUTH/SET_USER", payload: data });
            } catch (e) {}
        })();
    }, [dispatch]);

    const showPopupHandler = useCallback(() => {
        setIsShowPopup(true);
    }, []);

    const hidePopupHandler = useCallback(() => {
        setIsShowPopup(false);
    }, []);

    const showMobileMenuHandler = useCallback(() => {
        setIsShowMobileMenu(true);
    }, []);

    const hideMobileMenuHandler = useCallback(() => {
        setIsShowMobileMenu(false);
    }, []);

    return (
        <div className="landing">
            <LandingHeader
                user={user}
                isShowMobileMenu={isShowMobileMenu}
                showMobileMenuHandler={showMobileMenuHandler}
                hideMobileMenuHandler={hideMobileMenuHandler}
            />
            <LandingHero user={user} showPopupHandler={showPopupHandler} />
            <LandingProblems />
            <LandingAbout showPopupHandler={showPopupHandler} />
            <LandingWhy showPopupHandler={showPopupHandler} />
            <LandingDocuments />
            <LandingMethodology />
            <LandingOurMethodologists />
            <LandingCompany />
            <LandingContacts />
            <LandingFooter />

            {isShowPopup && <LandingPopup hidePopupHandler={hidePopupHandler} />}
            {isShowMobileMenu && (
                <LandingMobileMenu
                    user={user}
                    showPopup={showPopupHandler}
                    hideMobileMenu={hideMobileMenuHandler}
                />
            )}
        </div>
    );
};
