import React, { memo } from "react";

import companyImg from "static/img/landing/company-img.png";
import loveIcon from "static/img/landing/company-love.svg";

export const LandingCompany = memo(() => {
    return (
        <section className="landing__section" id="landing-company">
            <div className="landing__company">
                <div className="landing__company-block--left">
                    <h2 className="landing__section-title landing__company-title">
                        Компания Лаборатория систем саморазвития нацелена на создание гибких IT
                        систем для потребностей молодого поколения и комфортной цифровой среды, где
                        каждый может получить поддержку, найти подходы к решению своих проблем и
                        быть услышанным.
                    </h2>
                    <p className="landing__company-subtitle">
                        TestU.online — одна из разработанных платформ, отвечающая современным
                        вызовам психологического здоровья подрастающего поколения. Наша платформа
                        упрощает работу педагогов-психологов, добавляет интерактив в их рабочее
                        взаимодействие с учащимися, позволяет пользоваться эффективными методиками
                        по выявлению актуальных проблем учащихся.
                    </p>
                    <div className="landing__company-signature">
                        <img src={loveIcon} alt="" />
                        <p>С заботой о будущих поколениях, команда TestU</p>
                    </div>
                </div>

                <div className="landing__company-block--right">
                    <img src={companyImg} alt="" />
                </div>
            </div>
        </section>
    );
});
