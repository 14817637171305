import React, { memo, useState } from "react";
import { IMaskInput } from "react-imask";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { showAlert } from "redux/actions/setAppActions";
import { validateEmail, validatePhone } from "helpers/validators";
import termsOfUse from "static/docs/LSSR_terms_of_use.pdf";

const initForm = {
    surname: "",
    name: "",
    patronymic: "",
    login: "",
    email: "",
    phone: "",
    city: "",
    company: "",
};

const formFields = {
    surname: "Фамилия",
    name: "Имя",
    patronymic: "Отчество",
    login: "Логин",
    email: "Email",
    phone: "Номер телефона",
    city: "Город",
    company: "Наименование организации",
};

export const LandingPopupForm = memo(({ sendForm }) => {
    const dispatch = useDispatch();
    const [form, setForm] = useState(initForm);
    const [incorrectFields, setIncorrectFields] = useState([]);

    const changeFormHandler = (e) => {
        let { name, value } = e.target;

        if (incorrectFields.includes(name)) {
            setIncorrectFields(incorrectFields.filter((item) => item !== name));
        }

        if (["name", "surname"].includes(name) && value.length > 20) {
            return;
        }

        const newValue = ["city", "company", "phone"].includes(name) ? value : value.trim();

        setForm({
            ...form,
            [name]: newValue,
        });
    };

    const sendFormHandler = () => {
        const errorFields = [];
        const forbiddenSymbols = /[%;="*?#&()_~]/gi;
        const forbiddenSymbolsInstitution = /[%;=*?#&()_~]/gi;

        if (
            form.surname.length < 2 ||
            form.surname.length > 20 ||
            forbiddenSymbols.test(form.surname)
        )
            errorFields.push("surname");
        if (form.name.length < 2 || form.name.length > 20 || forbiddenSymbols.test(form.name))
            errorFields.push("name");
        if (
            form.patronymic.length < 2 ||
            form.patronymic.length > 20 ||
            forbiddenSymbols.test(form.patronymic)
        )
            errorFields.push("patronymic");
        if (!form.login) errorFields.push("login");
        if (!validateEmail(form.email)) errorFields.push("email");
        if (!validatePhone(form.phone)) errorFields.push("phone");
        if (!form.city || forbiddenSymbols.test(form.city)) errorFields.push("city");
        if (!form.company || forbiddenSymbolsInstitution.test(form.company))
            errorFields.push("company");

        if (errorFields.length) {
            const incorrectFieldsMessage = errorFields.map((item) => formFields[item]).join("\n");
            setIncorrectFields(errorFields);
            dispatch(
                showAlert({
                    type: "error",
                    text: `Некорректно заполнены поля:\n\n${incorrectFieldsMessage}`,
                })
            );

            return;
        }

        sendForm(form);
    };

    return (
        <div className="landing__popup-content-flex">
            <div>
                <h2 className="landing__popup-content-title">Заполните данные</h2>
                <p className="landing__popup-content-subtitle">
                    Мы свяжемся с вами для предоставления бесплатного доступа в систему
                </p>

                <div className="landing__popup-content-form">
                    <div
                        className={classNames(
                            "landing-contacts__input-field landing__popup-content-form-field",
                            { error: incorrectFields.includes("surname") }
                        )}
                    >
                        <input
                            placeholder="Фамилия"
                            id="surname"
                            type="text"
                            name="surname"
                            onChange={changeFormHandler}
                            value={form.surname}
                        />
                    </div>
                    <div
                        className={classNames(
                            "landing-contacts__input-field landing__popup-content-form-field",
                            { error: incorrectFields.includes("name") }
                        )}
                    >
                        <input
                            placeholder="Имя"
                            id="name"
                            type="text"
                            name="name"
                            onChange={changeFormHandler}
                            value={form.name}
                        />
                    </div>
                    <div
                        className={classNames(
                            "landing-contacts__input-field landing__popup-content-form-field",
                            { error: incorrectFields.includes("patronymic") }
                        )}
                    >
                        <input
                            placeholder="Отчество"
                            id="patronymic"
                            type="text"
                            name="patronymic"
                            onChange={changeFormHandler}
                            value={form.patronymic}
                        />
                    </div>
                    <div
                        className={classNames(
                            "landing-contacts__input-field landing__popup-content-form-field",
                            { error: incorrectFields.includes("login") }
                        )}
                    >
                        <input
                            placeholder="Логин (нужен для входа в систему)"
                            id="login"
                            type="text"
                            name="login"
                            onChange={changeFormHandler}
                            value={form.login}
                        />
                    </div>
                    <div
                        className={classNames(
                            "landing-contacts__input-field landing__popup-content-form-field",
                            { error: incorrectFields.includes("email") }
                        )}
                    >
                        <input
                            placeholder="Email"
                            id="email"
                            type="email"
                            name="email"
                            onChange={changeFormHandler}
                            value={form.email}
                        />
                    </div>
                    <div
                        className={classNames(
                            "landing-contacts__input-field landing__popup-content-form-field",
                            { error: incorrectFields.includes("phone") }
                        )}
                    >
                        <IMaskInput
                            placeholder="+7(000)000-00-00"
                            id="phone"
                            type="text"
                            name="phone"
                            onChange={changeFormHandler}
                            value={form.phone}
                            mask="+{7}(000)000-00-00"
                        />
                    </div>
                    <div
                        className={classNames(
                            "landing-contacts__input-field landing__popup-content-form-field",
                            { error: incorrectFields.includes("city") }
                        )}
                    >
                        <input
                            placeholder="Город"
                            id="city"
                            type="text"
                            name="city"
                            onChange={changeFormHandler}
                            value={form.city}
                        />
                    </div>
                    <div
                        className={classNames(
                            "landing-contacts__input-field landing__popup-content-form-field",
                            { error: incorrectFields.includes("company") }
                        )}
                    >
                        <input
                            placeholder="Наименование организации"
                            id="company"
                            type="text"
                            name="company"
                            onChange={changeFormHandler}
                            value={form.company}
                        />
                    </div>
                </div>
            </div>

            <div className="landing__popup-content-flex-bottom step2">
                <button
                    className="landing__button landing__button-secondary landing__popup-content-button"
                    onClick={sendFormHandler}
                >
                    Отправить заявку
                </button>
                <p>
                    Нажимая “Зарегистрироваться”, вы соглашаетесь с условиями{" "}
                    <a href={termsOfUse} target="_blank" rel="noreferrer">
                        политики конфиденциальности
                    </a>
                </p>
            </div>
        </div>
    );
});
