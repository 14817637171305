import React, { memo } from "react";

import icon1 from "static/img/landing/methodology-icon-1.svg";
import icon2 from "static/img/landing/methodology-icon-2.svg";
import icon3 from "static/img/landing/methodology-icon-3.svg";
import book from "static/img/landing/methodology-book.png";

export const LandingMethodology = memo(() => {
    return (
        <section className="landing__section" id="landing-methodology">
            <div className="landing__methodology">
                <div className="landing__methodology-separator landing__methodology-separator--top">
                    <div />
                </div>
                <img className="landing__methodology-notebook" src={book} alt="" />
                <div className="landing__methodology-ellipse" />
                <div className="landing__methodology-inner">
                    <div className="landing__methodology-block-text">
                        <h2 className="landing__section-title landing__methodology-title">
                            Нам важно было создать <span className="comfortable">удобный</span> и
                            методически правильный <span className="product">продукт</span>
                        </h2>
                        <p className="landing__methodology-subtitle">
                            Поэтому в процессе разработки платформы TestU.online мы провели
                            масштабный социологический опрос экспертов, который лег в основу нашей
                            методологии.
                        </p>
                    </div>

                    <div className="landing__methodology-content">
                        <div className="landing__methodology-content-block">
                            <span>1</span>
                            <img src={icon1} alt="" />
                            <p>Доктор наук в качестве эксперта</p>
                        </div>
                        <div className="landing__methodology-content-block">
                            <span>8</span>
                            <img src={icon2} alt="" />
                            <p>Кандидатов наук в качестве экспертов</p>
                        </div>
                        <div className="landing__methodology-content-block">
                            <span>300+</span>
                            <img src={icon3} alt="" />
                            <p>Педагогов психологов опрошено</p>
                        </div>
                    </div>
                </div>

                <div className="landing__methodology-separator landing__methodology-separator--bottom">
                    <div />
                </div>
            </div>
        </section>
    );
});
