import React, { memo } from "react";

import methodologist1 from "static/img/landing/methodologist-1.png";
import methodologist2 from "static/img/landing/methodologist-2.png";

export const LandingOurMethodologists = memo(() => {
    return (
        <section className="landing__section">
            <div className="landing__methodologists">
                <div className="landing__methodologists-line landing__methodologists-line--blue" />
                <div className="landing__methodologists-line landing__methodologists-line--yellow" />

                <h2 className="landing__section-title landing__methodologists-title">
                    Наши <span>методологи</span>
                </h2>

                <div className="landing__methodologists-content">
                    <div className="landing__methodologists-content-block">
                        <img src={methodologist1} alt="" />
                        <h4 className="landing__methodologists-content-block-name">
                            Богдановская Ирина Марковна
                        </h4>
                        <div className="landing__methodologists-content-block-desc">
                            <p>Психолог, кандидат психологических наук</p>
                            <p className="big">
                                Доцент кафедры психологии профессиональной деятельности и
                                информационных технологий в образовании РГПУ им А.И. Герцена
                            </p>
                            <p>Стаж работы в системе образования - более 20 лет</p>
                        </div>
                    </div>

                    <div className="landing__methodologists-content-block">
                        <img src={methodologist2} alt="" />
                        <h4 className="landing__methodologists-content-block-name">
                            Кошелева Александра Николаевна
                        </h4>
                        <div className="landing__methodologists-content-block-desc">
                            <p>Психолог, кандидат психологических наук</p>
                            <p className="big">
                                Доцент кафедры психологии профессиональной деятельности и
                                информационных технологий в образовании РГПУ им А.И. Герцена
                            </p>
                            <p>Стаж работы в системе образования - более 20 лет</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
});
