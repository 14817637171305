import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "redux/actions/setUserActions";
import { ReactComponent as PersonSVG } from "../static/img/ic-person.svg";
import { ReactComponent as LockSVG } from "../static/img/ic-lock.svg";
import { BackButton } from "components/BackButton";
import axios from "axios";
import { Layout } from "components/Layout";
import TextareaAutosize from "react-textarea-autosize";
import { PersonalData } from "components/PersonalData";

/**
 * @api {get} /settings настройка профиля пользователя
 * @apiGroup profile
 * @apiName настройка профиля пользователя
 *
 * @apiDescription <p>Страница настроек настройка профиля пользователя</p>
 * <p>Компонент <code>SettingsPage.js</code> <a href="../client/src/pages/SettingsPage.js">ссылка на файл</a></p>
 */

export const SettingsPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [section, setSection] = useState("");
    const { user, role } = useSelector((state) => state.auth);
    const callback = useCallback(() => {
        setSection("");
    }, []);
    const [form, setForm] = useState({});

    useEffect(() => {
        if (user) {
            setForm({
                sex: user.sex,
                name: user.name,
                surname: user.surname,
                birthday: user.birthday,
                email: user.email,
                phone: user.phone,
                institution: user.institution,
                emailNew: user.emailNew,
                telegram: user.telegram,
                schedule: user.schedule,
            });
        }
    }, [user]);
    const changeHandler = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    if (section === "personalData") {
        return <PersonalData callback={callback} />;
    }
    if (section === "schedule") {
        return (
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => {
                                setSection("");
                            }}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                        ></button>
                    </header>
                    <div className="page__content">
                        <h3>График работы</h3>
                        <div className="auth-page__input-group">
                            <div className="input-field">
                                <TextareaAutosize
                                    className="textarea-autosize"
                                    placeholder="График работы"
                                    id="schedule"
                                    name="schedule"
                                    onChange={changeHandler}
                                    value={form.schedule}
                                />
                                <label htmlFor="schedule">График работы</label>
                            </div>
                        </div>
                        <p style={{ opacity: 0.5 }}>
                            Здесь вы можете указать ваши часы приема, расположение вашего кабинета,
                            а также контактную информацию при необходимости. Эта информация будет
                            доступна для каждого вашего студента
                        </p>
                    </div>
                    <button
                        onClick={() => dispatch(updateUser(form))}
                        style={{ marginTop: 20 }}
                        className="main-btn"
                    >
                        Сохранить
                    </button>
                </div>
            </Layout>
        );
    }

    const logout = async () => {
        await axios.post("auth/logout");
        dispatch({
            type: "AUTH/SET_USER",
            payload: {
                user: null,
                role: null,
            },
        });
        history.push("/");
    };

    return (
        <Layout>
            <div style={{ paddingBottom: "54px" }} className="page settings-page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title settings-page__title">Настройки профиля</p>
                </header>
                <div className="settings-page__main">
                    {/*<button className="settings-page__btn">Изменить фото профиля</button>*/}
                </div>
                <div className="settings-page__sections">
                    <div className="settings-page__sections-wrapper">
                        <p className="settings-page__sections-title">Профиль</p>
                        <div className="settings-sections">
                            <p
                                onClick={() => setSection("personalData")}
                                className="settings-sections__item"
                            >
                                <PersonSVG />
                                Персональные данные
                            </p>
                            {role === "psych" ? (
                                <p
                                    onClick={() => setSection("schedule")}
                                    className="settings-sections__item"
                                >
                                    <PersonSVG />
                                    График работы
                                </p>
                            ) : (
                                ""
                            )}

                            <Link to="/settings/security" className="settings-sections__item">
                                <LockSVG />
                                Безопасность и вход
                            </Link>
                            {/*<p className="settings-sections__item">*/}
                            {/*    <DialogSVG />*/}
                            {/*    Привязка мессенджеров*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    <div className="settings-page__footer">
                        <button
                            onClick={logout}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            className="logout-btn"
                        >
                            Выйти из учетной записи
                        </button>
                        <p style={{ marginTop: "3rem", opacity: 0.3 }}>
                            По любым вопросам технических сбоев или проблем при пользовании
                            платформой Вы можете обратиться по почте help@testu.online и мы
                            оперативно решим ваш вопрос
                        </p>
                        <p style={{ marginTop: "1rem", opacity: 0.3 }}>
                            По любым вопросам предложений по развитию платформы Вы можете обратиться
                            по почте school@testu.online и мы оперативно рассмотрим ваше предложение
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
