import React, { memo } from "react";

import problems from "static/img/landing/problems.svg";

export const LandingProblems = memo(() => {
    return (
        <section className="landing__section">
            <div className="landing__problems">
                <h2 className="landing__problems-title">Проблематика</h2>

                <div className="landing__problems-img">
                    <img src={problems} alt="" />
                </div>
            </div>
        </section>
    );
});
