import React, { memo, useState } from "react";
import classNames from "classnames";

import whyPhone from "static/img/landing/why-phone.png";

export const LandingWhy = memo(({ showPopupHandler }) => {
    const [tab, setTab] = useState(1);

    return (
        <section className="landing__section" id="landing-why">
            <div className="landing__why">
                <div className="landing__why-block--left">
                    <div>
                        <h2 className="landing__section-title landing__why-title">
                            Зачем наше решение
                        </h2>
                        <div className="landing__why-buttons-tab">
                            <button
                                onClick={() => {
                                    setTab(1);
                                }}
                                className={classNames({ active: tab === 1 })}
                            >
                                Психологу
                            </button>
                            <button
                                onClick={() => {
                                    setTab(2);
                                }}
                                className={classNames({ active: tab === 2 })}
                            >
                                Руководству
                            </button>
                            <button
                                onClick={() => {
                                    setTab(3);
                                }}
                                className={classNames({ active: tab === 3 })}
                            >
                                Студенту
                            </button>
                        </div>

                        {tab === 1 && (
                            <div className="landing__why-content">
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">01</span>
                                    <p>
                                        Валидированный комплекс методик под актуальные проблемы
                                        учащихся
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">02</span>
                                    <p>Конструктор опросов для любого типа анкетирования</p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">03</span>
                                    <p>
                                        Автоматизированная статистика по результатам тестирования и
                                        отчеты
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">04</span>
                                    <p>
                                        Доступность в использовании сервиса только с помощью
                                        мобильного телефона
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">05</span>
                                    <p>Рекомендации по интерпретации результатов тестирования</p>
                                </div>
                            </div>
                        )}

                        {tab === 2 && (
                            <div className="landing__why-content">
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">01</span>
                                    <p>
                                        Аналитические отчеты по фильтрам: Факультет, группа, пол,
                                        возраст, специальные группы
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">02</span>
                                    <p>
                                        Выявление аномальных паттернов поведения и конфликтов внутри
                                        ВУЗа
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">03</span>
                                    <p>
                                        Полное покрытие числа учащихся психодиагностическим
                                        тестированием (в том числе во время удаленной учебы)
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">04</span>
                                    <p>
                                        Возможность интеграции Платформы с существующими
                                        Информационными системами ВУЗа (запись, ВКС и т.д.)
                                    </p>
                                </div>
                            </div>
                        )}

                        {tab === 3 && (
                            <div className="landing__why-content">
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">01</span>
                                    <p>
                                        Кнопка «Хочу поговорить» для оперативного получения
                                        поддержки психолога
                                    </p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">02</span>
                                    <p>Современный понятный интерфейс</p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">03</span>
                                    <p>Получение и хранение рекомендаций психолога на платформе</p>
                                </div>
                                <div className="landing__why-content-item">
                                    <span className="landing__rounded-number">04</span>
                                    <p>
                                        Персональные рекомендации для поддержки эмоционального
                                        состояния
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>

                    <button
                        onClick={showPopupHandler}
                        className="landing__button landing__button-secondary landing__why-button-want"
                    >
                        Хочу внедрить
                    </button>
                </div>

                <div className="landing__section-block-dark landing__why-block--right">
                    <img src={whyPhone} alt="" />
                    <div className="ellipse" />
                </div>
            </div>
        </section>
    );
});
