import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";

import { createNote, getNotes } from "redux/actions/setNoteActions";
import { BackButton } from "components/BackButton";
import { Loader } from "components/Loader";
import { Layout } from "components/Layout";
import { CreateNote } from "components/CreateNote";

/**
 * @api {get} /notes?pupilId=pupilId заметки
 * @apiGroup profile
 * @apiName заметки
 *
 * @apiQuery {String} pupilId ID ученика
 *
 * @apiDescription <p>Страница создания и просмотра списка заметок</p>
 * <p>Компонент <code>NotesPage.js</code> <a href="../client/src/pages/NotesPage.js">ссылка на файл</a></p>
 */

export const NotesPage = () => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const [pupilId, setPupilId] = useState(null);
    const [note, setNote] = useState(null);
    const [isCreate, setIsCreate] = useState(false);
    const { notes } = useSelector((state) => state.note);
    const { loading } = useSelector((state) => state.app);
    const { role } = useSelector((state) => state.auth);

    useEffect(() => {
        const query = new URLSearchParams(search);
        const pupilId = query.get("studentId");
        dispatch(getNotes(pupilId));
        setPupilId(pupilId);
    }, [dispatch, search]);

    const createHandler = async (form) => {
        dispatch(createNote({ ...form, pupilId }));
    };

    if (loading) return <Loader />;

    if (isCreate) {
        return (
            <CreateNote closeHandler={() => setIsCreate(!isCreate)} createHandler={createHandler} />
        );
    }

    if (note)
        return (
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => setNote(null)}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                        />
                        <p className="page__title">
                            <Moment locale="ru" format="DD MMM, YYYY">
                                {note.createdAt}
                            </Moment>
                        </p>
                    </header>
                    <div className="page__content">
                        <h3>{note.title}</h3>
                        <p>{note.text}</p>
                    </div>
                </div>
            </Layout>
        );

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    {role === "psych" && (
                        <button
                            onClick={() => setIsCreate(!isCreate)}
                            className=" icon-btn  page__icon-btn  page__icon-btn_right  icon-btn_add"
                        />
                    )}
                    <p className="page__title">Заметки</p>
                </header>
                <div className="school-page__wrapper">
                    <div className="list">
                        {notes.map((note) => {
                            const max = 70;
                            return (
                                <div key={note._id} className="list__item-wrap">
                                    <p className="list__item-date">
                                        <Moment locale="ru" format="DD MMM, YYYY">
                                            {note.createdAt}
                                        </Moment>
                                    </p>
                                    <div className="list__item" onClick={() => setNote(note)}>
                                        <p>{note.title}</p>
                                        <p className="list__desc">
                                            {note.text.length > max
                                                ? note.text.slice(0, max).concat("...")
                                                : note.text}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {role === "psych" && (
                    <button
                        onClick={() => setIsCreate(!isCreate)}
                        className="main-btn main-btn_yellow"
                    >
                        Написать заметку
                    </button>
                )}
            </div>
        </Layout>
    );
};
