import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Layout } from "components/Layout";
import { Range } from "components/Range";
import { BackButton } from "components/BackButton";
import { declinations } from "helpers/declinations";

/**
 * @api {get} /statistic/schools статистика школ
 * @apiGroup statistic
 * @apiName статистика школ
 *
 * @apiDescription <p>Страница статистики школ</p>
 * <p>Компонент <code>StatisticSchools.js</code> <a href="../client/src/pages/statistic/StatisticSchools.js">ссылка на файл</a></p>
 */

export const StatisticSchools = () => {
    const [schools, setSchools] = useState([]);

    useEffect(() => {
        (async () => {
            const { data } = await axios.get("statistic/schools");
            setSchools(data);
        })();
    }, []);

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Статистика</p>
                </header>

                <div>
                    {schools.map((school, index) => (
                        <Link
                            key={index}
                            className="statistic-condition"
                            to={`/statistic/faculties/${school._id}`}
                        >
                            <p className="statistic-condition__title">{school.name}</p>

                            <Range
                                total={school.total}
                                norm={school.positively}
                                // notNorm={school.negatively}
                                notNorm={school.total - school.positively}
                            />

                            <div className="statistic-condition__footer">
                                <span className="statistic-condition__indicator green">
                                    {school.positively}
                                </span>
                                {/*<span className="statistic-condition__indicator red">*/}
                                {/*    {school.negatively}*/}
                                {/*</span>*/}
                                <span className="statistic-condition__indicator blue">
                                    {/*{school.total - school.positively - school.negatively}*/}
                                    {school.total - school.positively}
                                </span>
                                <p className="statistic-condition__members">
                                    {school.total} {declinations(school.total, "test")}
                                </p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </Layout>
    );
};
