import React, { useState, useEffect, useCallback } from "react";
import { useParams, Redirect, Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { FaRegTrashAlt } from "react-icons/fa";
import { BiUser } from "react-icons/bi";
import TextareaAutosize from "react-textarea-autosize";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import { SureAlert } from "components/SureAlert";

/**
 * @api {get} /pupils/:pupilId/meetings/create создание встречи для ученика
 * @apiGroup meetings
 * @apiName создание встречи для ученика
 *
 * @apiParam {String} pupilId ID ученика
 *
 * @apiDescription <p>Страница создания встречи для ученика</p>
 * <p>Компонент <code>MeetingForm.js</code> <a href="../client/src/pages/meetings/MeetingForm.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /pupils/:pupilId/meetings/:meetingId редактирование встречи для ученика
 * @apiGroup meetings
 * @apiName редактирование встречи для ученика
 *
 * @apiParam {String} pupilId ID ученика
 * @apiParam {String} meetingId ID встречи
 *
 * @apiDescription <p>Страница редактирования встречи для ученика</p>
 * <p>Компонент <code>MeetingForm.js</code> <a href="../client/src/pages/meetings/MeetingForm.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /meetings/:meetingId редактирование встречи по id
 * @apiGroup meetings
 * @apiName редактирование встречи по id
 *
 * @apiParam {String} meetingId ID встречи
 *
 * @apiDescription <p>Страница редактирования встречи по id</p>
 * <p>Компонент <code>MeetingForm.js</code> <a href="../client/src/pages/meetings/MeetingForm.js">ссылка на файл</a></p>
 */

const initialState = {
    name: "",
    date: "",
    description: "",
};

export const MeetingForm = () => {
    const { pupilId, meetingId } = useParams();
    const [redirect, setRedirect] = useState(false);
    const [form, setForm] = useState(initialState);
    const [meeting, setMeeting] = useState(null);

    const { role } = useSelector((state) => state.auth);
    const [isSureAlert, setIsSureAlert] = useState(false);

    function onSendClick() {
        setIsSureAlert(true);
    }

    function onCloseClick() {
        setIsSureAlert(false);
    }

    useEffect(() => {
        if (meetingId) {
            (async () => {
                const { data } = await axios.get(`meetings/${meetingId}`);
                const { name, description, date } = data;
                setMeeting(data);
                setForm({
                    ...initialState,
                    name,
                    description,
                    date: moment(date).utc().format("DD.MM.YYYY HH:mm"),
                });
            })();
        }
    }, [meetingId]);

    const changeHandler = (event) => {
        const { name, value } = event.target;

        setForm({ ...form, [name]: value });
    };

    const changeDateHandler = (value) => {
        if (typeof value !== "string") {
            const now = moment();
            let date = moment(value).format("DD.MM.YYYY HH:mm");

            if (value < now) {
                const round = 5 - (now.minute() % 5);
                date = now.add(round, "minutes").format("DD.MM.YYYY HH:mm");
            }

            setForm({ ...form, date });
        }
    };

    const isValidDate = useCallback((current) => current.isAfter(moment().subtract(1, "day")), []);

    const submitHandler = async (e) => {
        e.preventDefault();
        const date = moment(form.date, "DD.MM.YYYY HH:mm").format("YYYY-MM-DDTkk:mm");
        const data = { ...form, date };

        if (meetingId) {
            await axios.put(`meetings/${meetingId}`, data);
        } else {
            await axios.post("meetings", { ...data, pupilId });
        }

        setRedirect(true);
    };

    const deleteHandler = async (e) => {
        e.preventDefault();

        if (window.confirm("Do you really delete this item?")) {
            await axios.delete(`meetings/${meetingId}`);
            setRedirect(true);
        }
    };

    if (redirect) {
        return <Redirect to={pupilId ? `/students/${pupilId}/meetings` : "/meetings"} />;
    }

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Встречи</p>
                    {role === "psych" ? (
                        meeting?.pupil && (
                            <Link
                                to={`/students/${meeting.pupil}`}
                                style={{ padding: "8px" }}
                                className="icon-btn page__icon-btn page__icon-btn_right"
                            >
                                <BiUser />
                            </Link>
                        )
                    ) : (
                        <Link
                            to={`/dashboard`}
                            style={{ padding: "8px" }}
                            className="icon-btn page__icon-btn page__icon-btn_right"
                        >
                            <BiUser />
                        </Link>
                    )}
                </header>

                <div className="school-page__wrapper">
                    <h3>Детали встречи</h3>
                    <div className="input-field input-field_purple">
                        <TextareaAutosize
                            className="textarea-autosize"
                            placeholder="Тема встречи"
                            id="name"
                            name="name"
                            onChange={changeHandler}
                            value={form.name}
                            disabled={role === "pupil"}
                        />
                        <label htmlFor="name">Тема</label>
                    </div>
                    <div className="input-field input-field_purple">
                        <TextareaAutosize
                            className="textarea-autosize"
                            placeholder="Подробности встречи"
                            id="description"
                            name="description"
                            onChange={changeHandler}
                            value={form.description}
                            disabled={role === "pupil"}
                        />
                        <label htmlFor="description">Описание и место</label>
                    </div>

                    <div className="input-field input-field_purple">
                        <Datetime
                            value={form.date}
                            onChange={changeDateHandler}
                            timeConstraints={{ minutes: { step: 5 } }}
                            inputProps={{
                                placeholder: "Дата встречи",
                                readOnly: true,
                                disabled: role === "pupil",
                            }}
                            isValidDate={isValidDate}
                        />
                    </div>
                </div>

                {role === "psych" && (
                    <button
                        onClick={onSendClick}
                        disabled={!form.name || !form.date}
                        className="main-btn main-btn_purple"
                    >
                        {meetingId ? "Изменить" : "Назначить"}
                    </button>
                )}
                {role === "psych" && (
                    <button
                        style={{
                            color: "fff",
                            width: "58px",
                            height: "58px",
                            margin: "1.5rem auto",
                            backgroundColor: "#FE2B2B",
                            borderRadius: "50%",
                            boxShadow: "0px 2px 12px rgb(0 110 255 / 40%)",
                        }}
                        onClick={deleteHandler}
                    >
                        <FaRegTrashAlt color="fff" />
                    </button>
                )}
            </div>

            {isSureAlert && (
                <SureAlert
                    type="createMeeting"
                    handleClose={onCloseClick}
                    handleSubmit={(e) => {
                        submitHandler(e);
                        onCloseClick();
                    }}
                />
            )}
        </Layout>
    );
};
