import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { getPupils } from "redux/actions/setPupilsAction";
import { getSchools } from "redux/actions/setSchoolActions";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";

/**
 * @api {get} /riskGroups группы риска
 * @apiGroup profile
 * @apiName группы риска
 *
 * @apiDescription <p>Страница групп риска</p>
 * <p>Компонент <code>RiskGroups.js</code> <a href="../client/src/pages/RiskGroups.js">ссылка на файл</a></p>
 */

export const RiskGroups = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const pupilsLoaded = useSelector((state) => state.pupils);
    const { schools } = useSelector((state) => state.school);
    const [pupils, setPupils] = useState([]);
    const [riskGroupsFilter, setRiskGroupsFilter] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isShowFilterPopup, setIsShowFilterPopup] = useState(false);

    useEffect(() => {
        if (!pupilsLoaded?.pupilsData?.length) {
            dispatch(getPupils());
        }

        if (!schools?.length) {
            dispatch(getSchools());
        }

        (async () => {
            const { data } = await axios.get(`categories`);
            setCategories(data.categories);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const pupilsList = useMemo(
        () =>
            pupilsLoaded.pupilsData.filter((pupil) => pupil.categories.length && !pupil.isArchive),
        [pupilsLoaded]
    );

    useEffect(() => {
        if (pupilsLoaded.pupilsData) {
            setPupils(pupilsList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pupilsLoaded]);

    useEffect(() => {
        if (!riskGroupsFilter.length) {
            setPupils(pupilsList);
        } else {
            const filteredPupilsList = new Set();

            for (const pupil of pupilsList) {
                for (const category of pupil.categories) {
                    if (riskGroupsFilter.includes(category)) {
                        filteredPupilsList.add(pupil);
                    }
                }
            }

            setPupils(Array.from(filteredPupilsList));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [riskGroupsFilter]);

    const clickHandler = (pupilId) => {
        if (window.location.href.indexOf("/meetings/groups/") !== -1) {
            history.push(`/students/${pupilId}/meetings/create`);
        } else {
            history.push(`/students/${pupilId}`);
        }
    };

    const changeFilterHandler = (e) => {
        const id = e.target.value;
        let newFilter = [];

        if (riskGroupsFilter.includes(id)) {
            newFilter = riskGroupsFilter.filter((item) => item !== id);
        } else {
            newFilter = [...riskGroupsFilter];
            newFilter.push(id);
        }

        setRiskGroupsFilter(newFilter);
    };

    const showFilterPopupHandler = () => {
        setIsShowFilterPopup(!isShowFilterPopup);
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Группы риска</p>
                    <button
                        className="risk-groups__btn icon-btn page__icon-btn page__icon-btn_right icon-btn_filter icon-btn_filter_active"
                        onClick={showFilterPopupHandler}
                    >
                        {isShowFilterPopup && (
                            <div
                                className="risk-groups__filter"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                {categories.map(({ _id, title }) => {
                                    const isChecked = riskGroupsFilter.includes(_id);

                                    return (
                                        <div className="risk-groups__filter-item" key={_id}>
                                            <input
                                                id={_id}
                                                type="checkbox"
                                                value={_id}
                                                checked={isChecked}
                                                onChange={changeFilterHandler}
                                            />
                                            <label htmlFor={_id}>{title}</label>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </button>
                </header>

                <div className="school-page__wrapper">
                    <div className="list gesturedZone risk-groups__list">
                        {pupils.map((pupil) => {
                            const school = schools.find(({ _id }) => _id === pupil.class.school);

                            return (
                                <div
                                    key={pupil._id}
                                    onClick={() => {
                                        clickHandler(pupil._id);
                                    }}
                                    className="risk-groups__list-item"
                                >
                                    <div>
                                        <span className="risk-groups__tag">
                                            Групп риска {pupil.categories?.length}
                                        </span>
                                        <p>
                                            {pupil.name} {pupil.surname}
                                        </p>
                                    </div>

                                    <p className="risk-groups__school">
                                        {school && <span>{school.name},</span>}{" "}
                                        <span>{pupil.class.name}</span>
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    );
};
