import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Moment from "react-moment";
import "moment/locale/ru";

import { useCreateReport } from "hooks/useCreateReport";
import { declinations } from "helpers/declinations";
import { getSortedTestsByDate } from "helpers/tests";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import { Range } from "components/Range";

/**
 * @api {get} /statistic/pupils/:id статистика ученика
 * @apiGroup statistic
 * @apiName статистика ученика
 *
 * @apiParam {String} id ID ученика
 *
 * @apiDescription <p>Страница статистики выбранного ученика</p>
 * <p>Компонент <code>StatisticPupil.js</code> <a href="../client/src/pages/statistic/StatisticPupil.js">ссылка на файл</a></p>
 */

export const StatisticPupil = () => {
    const pupilId = useParams().id;
    const [norm, setNorm] = useState(0);
    const [tests, setTests] = useState([]);
    const [nullNorm, setNullNorm] = useState(0);
    const { filters } = useSelector((state) => state.statistic);
    const [passedTests, setPassedTests] = useState([]);
    const [notPassedTests, setNotPassedTests] = useState([]);
    const { createReport, isReportLoading } = useCreateReport();

    useEffect(() => {
        if (pupilId) {
            const arr = [];

            if (filters.date) {
                arr.push(`date=${filters.date}`);
            }

            if (filters.condition) {
                arr.push(`condition=${filters.condition}`);
            }

            (async () => {
                const { data } = await axios.get(`statistic/pupils/${pupilId}?${arr.join("&")}`);

                setTests(data);
            })();
        }
    }, [pupilId, filters]);

    useEffect(() => {
        if (tests.length) {
            const passed = tests.find(({ _id }) => !!_id);
            const notPassed = tests.find(({ _id }) => _id === null);
            const empty = notPassed?.total;
            const passedFiltered = [];

            if (passed) {
                for (const test of passed.tests) {
                    const isExistIndex = passedFiltered.findIndex(
                        ({ conditionId }) => test.conditionId === conditionId
                    );

                    if (isExistIndex === -1) {
                        passedFiltered.push(test);
                    } else {
                        if (test.date > passedFiltered[isExistIndex].date) {
                            passedFiltered[isExistIndex] = test;
                        }
                    }
                }
            }

            if (notPassed) {
                setNotPassedTests(getSortedTestsByDate(notPassed.tests));
            }

            setPassedTests(getSortedTestsByDate(passedFiltered));
            setNorm(Number(passedFiltered.length));
            setNullNorm(Number(empty || !!empty));
        }
    }, [tests]);

    const createReportHandler = () => {
        const testsQuery = passedTests.map(({ _id }) => _id);

        createReport(
            `statistic/pupil-report/${pupilId}?tests=${testsQuery}`,
            `Отчет_${tests[0]?.pupil.surname}_${tests[0]?.pupil.name}.xlsx`
        );
    };

    return (
        <Layout>
            <div className="page statistic-details-page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title statistic-details-page__title">Статистика</p>
                    <Link
                        to={`/statistic/students/${pupilId}/filters`}
                        className={
                            !!Object.values(filters).filter((val) => !!val.length).length
                                ? `icon-btn page__icon-btn page__icon-btn_right icon-btn_filter icon-btn_filter_pupil icon-btn_filter_active`
                                : `icon-btn page__icon-btn icon-btn_filter_pupil page__icon-btn_right icon-btn_filter`
                        }
                    >
                        {!!Object.values(filters).filter((val) => !!val.length).length && (
                            <span>
                                {Object.values(filters).filter((val) => !!val.length).length}
                            </span>
                        )}
                    </Link>
                </header>

                <div className="statistic-details-page__main">
                    <div className="statistic-condition">
                        <p className="statistic-condition__title">
                            {tests[0]?.pupil.surname} {tests[0]?.pupil.name}
                        </p>
                        <Range total={norm + nullNorm} norm={norm} notNorm={nullNorm} />
                        <div className="statistic-condition__footer">
                            <span className="statistic-condition__indicator green">{norm}</span>
                            <span className="statistic-condition__indicator blue">{nullNorm}</span>
                            <p className="statistic-condition__members">
                                {norm + nullNorm} {declinations(norm + nullNorm, "test")}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="statistic-pupils">
                    <div className="statistic-pupils__wrapper">
                        <div className="statistic-pupils__group">
                            {!!passedTests.length && (
                                <div
                                    className="statistic-reports__btn statistic-reports__btn-class"
                                    onClick={createReportHandler}
                                >
                                    {isReportLoading ? (
                                        <p>Отчет загружается...</p>
                                    ) : (
                                        <p>Сформировать отчет</p>
                                    )}
                                </div>
                            )}

                            <div className="statistic-pupils__header">
                                <p className="statistic-pupils__title green">Проходили тест</p>
                                <span className="statistic-pupils__count">{norm}</span>
                            </div>
                            <div>
                                {passedTests.map((test) => (
                                    <Test key={test._id} test={test} />
                                ))}
                            </div>
                        </div>

                        <div className="statistic-pupils__group">
                            <div className="statistic-pupils__header">
                                <p className="statistic-pupils__title blue">Не проходили тест</p>
                                <span className="statistic-pupils__count">{nullNorm}</span>
                            </div>
                            <div>
                                {notPassedTests.map((test) => (
                                    <div key={test._id} className="statistic-pupils__item">
                                        <div>
                                            <p className="statistic-pupils__name">
                                                {test.condition}
                                            </p>
                                            <p className="statistic-pupils__details">
                                                <Moment locale="ru" format="HH:mm, DD MMM, YYYY">
                                                    {test.date}
                                                </Moment>
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const Test = ({ test }) => (
    <Link to={`/solutions/${test._id}`} className="statistic-pupils__item">
        <div>
            <p className="statistic-pupils__name">{test.condition}</p>
            <p className="statistic-pupils__details">
                <Moment locale="ru" format="HH:mm, DD MMM, YYYY">
                    {test.date}
                </Moment>
            </p>
        </div>
    </Link>
);
