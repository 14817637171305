import { combineReducers } from 'redux'
import { authReducer } from './authReducer'
import { appReducer } from './appReducer'
import { schoolReducer } from './schoolReducer'
import { testReducer } from './testReducer'
import { historyReducer } from './historyReducer'
import { statisticReducer } from './statisticReducer'
import { pupilReducer } from './pupilReducer'
import { adviceReducer } from './adviceReducer'
import { noteReducer } from './noteReducer'
import { alarmReducer } from './alarmReducer'
import { meetingReducer } from './meetingReducer'
import {createTestReducer} from "./createTestReducer";
import {pupilsReducer} from "./pupilsReducer";


export const rootReducer = combineReducers({
    auth: authReducer,
    app: appReducer,
    school: schoolReducer,
    test: testReducer,
    history: historyReducer,
    statistic: statisticReducer,
    pupil: pupilReducer,
    pupils: pupilsReducer,
    advice: adviceReducer,
    note: noteReducer,
    alarm: alarmReducer,
    meeting: meetingReducer,
    createTest: createTestReducer,
})