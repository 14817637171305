import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getConditionsINedded } from "redux/actions/setConditionActions";
import { showAlert } from "redux/actions/setAppActions";
import { declinations } from "helpers/declinations";
import { problems } from "pages/problems/problemsList";
import { Layout } from "components/Layout";
import { BackButton } from "components/BackButton";
import { Info } from "components/Info";
import "../static/scss/problemPage.scss";

/**
 * @api {get} /workWithMethods/problems/:id список методик по современным проблемам подросткового возраста
 * @apiGroup methods
 * @apiName список методик по современным проблемам подросткового возраста
 *
 * @apiParam {String} id ID проблемы
 *
 * @apiDescription <p>Страница со списком методик по современным проблемам подросткового возраста</p>
 * <p>Компонент <code>ProblemPage.js</code> <a href="../client/src/pages/ProblemPage.js">ссылка на файл</a></p>
 */

export default function ProblemPage() {
    const problemId = useParams().id;
    const history = useHistory();
    const dispatch = useDispatch();
    const { conditions } = useSelector((state) => state.test);

    const [isDescription, setIsDescription] = useState(false);
    const [info, setInfo] = useState(null);

    useEffect(() => {
        dispatch(getConditionsINedded());
    }, [dispatch]);

    const sendTestHandler = (condition) => {
        if (condition) {
            history.push(`/tests/create/${condition._id}`);
        } else {
            dispatch(showAlert({ type: "error", text: "Тест не найден" }));
        }
    };

    const showInfoHandler = (e, condition) => {
        if (!info) {
            e.stopPropagation();

            setInfo({
                name: condition.name,
                desc: condition.message,
                conditionId: condition._id,
                author: condition.methodAuthor,
                desTeacher: condition.desTeacher,
                message: condition.message,
                scales: condition.scales,
            });
        } else {
            setInfo(null);
        }
    };

    if (isDescription) {
        return (
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => {
                                setIsDescription(false);
                            }}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                        />

                        <p className="page__title">Описание</p>
                    </header>
                    <div className="workWithMethods-page__wrapper">
                        <div className="list">
                            <p className="problem-page__text">{problems[problemId].description}</p>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }

    if (info) {
        return (
            <Layout>
                <Info
                    name={info.name}
                    desc={info.desc}
                    handler={showInfoHandler}
                    conditionId={info.conditionId}
                    author={info.author}
                    desTeacher={info.desTeacher}
                    message={info.message}
                    scales={info.scales}
                />
            </Layout>
        );
    }

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">Работа с методиками</p>
                </header>
                <div className="workWithMethods-page__wrapper">
                    <h4 className="problem-page__h4">{problems[problemId].title}</h4>
                    {problems[problemId].description && (
                        <button
                            style={{ marginTop: 40 }}
                            className="main-btn"
                            onClick={() => {
                                setIsDescription(true);
                            }}
                        >
                            Показать описание
                        </button>
                    )}
                    <h4 className="problem-page__h4">Вам подойдут следующие методики: </h4>
                    {problems[problemId].methods
                        ? problems[problemId].methods.map((item) => {
                              const condition = conditions.find(({ _id }) => _id === item.id);

                              return (
                                  <React.Fragment key={item.name}>
                                      {condition ? (
                                          <div
                                              className="problem-page__card"
                                              onClick={() => {
                                                  sendTestHandler(condition);
                                              }}
                                          >
                                              <div>
                                                  <div className="problem-page__row">
                                                      <div className="problem-page__type problem-page__type_Test">
                                                          {item.type}
                                                      </div>
                                                  </div>
                                                  <h3 className="problem-page__h3">{item.name}</h3>
                                                  <h5 className="list__item-count list__item-count-margin">
                                                      {condition.body.questions.length} вопросов в
                                                      методике
                                                  </h5>
                                                  <h5 className="list__item-count">
                                                      {condition.scales.length}{" "}
                                                      {declinations(
                                                          condition.scales.length,
                                                          "scale"
                                                      )}
                                                  </h5>
                                              </div>

                                              <button
                                                  className="tests-list__info-btn"
                                                  onClick={(e) => {
                                                      showInfoHandler(e, condition);
                                                  }}
                                              />
                                          </div>
                                      ) : null}
                                  </React.Fragment>
                              );
                          })
                        : ""}
                </div>
            </div>
        </Layout>
    );
}
