import React, { memo } from "react";

import { LandingContactForm } from "components/landing/LandingContactForm";
import emailIcon from "static/img/landing/contacts-email.svg";
import phoneIcon from "static/img/landing/contacts-phone.svg";
import locationIcon from "static/img/landing/contacts-location.svg";

export const LandingContacts = memo(() => {
    return (
        <section className="landing__section" id="landing-contacts">
            <div className="landing__contacts">
                <h2 className="landing__section-title landing__contacts-title">
                    Вы можете задать нам любой вопрос
                </h2>

                <div className="landing__contacts-block">
                    <div className="landing__contacts-block--left">
                        <div>
                            <a
                                className="landing__contacts-contact"
                                href="mailto:ceo@testu.online"
                            >
                                <img src={emailIcon} alt="" />
                                <div>
                                    <span>Обсуждение сотрудничества и интеграции</span>
                                    <p>ceo@testu.online</p>
                                </div>
                            </a>
                            <a
                                className="landing__contacts-contact"
                                href="mailto:help@testu.online"
                            >
                                <img src={emailIcon} alt="" />
                                <div>
                                    <span>Техническая поддержка и интеграция</span>
                                    <p>help@testu.online</p>
                                </div>
                            </a>
                            <a className="landing__contacts-contact" href="tel:+79663006996">
                                <img src={phoneIcon} alt="" />
                                <p>+7 966 300 69 96</p>
                            </a>
                            <div className="landing__contacts-contact">
                                <img src={locationIcon} alt="" />
                                <p>
                                    Берсеневская набережная, 6<br />
                                    строение 3
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="landing__contacts-block--right">
                        <LandingContactForm />
                    </div>
                </div>
            </div>
        </section>
    );
});
