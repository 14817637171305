import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import logo from "static/img/logo.svg";
import userImg from "static/img/user.svg";
import arrowRight from "static/img/landing/arrow-right.svg";
import menuBurger from "static/img/landing/menu-burger.svg";
import menuBurgerClose from "static/img/landing/menu-burger-close.svg";

export const LandingHeader = memo((props) => {
    const { user, isShowMobileMenu, showMobileMenuHandler, hideMobileMenuHandler } = props;
    const [isShowLoginButtons, setIsShowLoginButtons] = useState(false);

    const showLoginButtonsHandler = () => {
        setIsShowLoginButtons((prevState) => !prevState);
    };

    return (
        <header className="landing-header">
            <div className="landing-header__logo-wrapper">
                <Link className="landing-header__logo" to="/">
                    <img src={logo} alt="logo" />
                </Link>
            </div>
            <nav className="landing-header__nav">
                <HashLink className="landing__button landing__button-primary" to="#landing-about">
                    О продукте
                </HashLink>
                <HashLink className="landing__button landing__button-primary" to="#landing-why">
                    Зачем наше решение
                </HashLink>
                <HashLink
                    className="landing__button landing__button-primary"
                    to="#landing-documents"
                >
                    Документы
                </HashLink>
                <HashLink
                    className="landing__button landing__button-primary"
                    to="#landing-methodology"
                >
                    Методология
                </HashLink>
                <HashLink
                    className="landing__button landing__button-primary"
                    to="#landing-contacts"
                >
                    Контакты
                </HashLink>
            </nav>
            <div className="landing-header__auth">
                {user ? (
                    <Link to="/dashboard" className="landing__button landing__button-profile">
                        <img src={userImg} alt="user" /> Профиль
                    </Link>
                ) : (
                    <div className="landing-header__auth-buttons">
                        <button
                            onClick={showLoginButtonsHandler}
                            className="landing__button landing__button-profile"
                        >
                            <img src={userImg} alt="user" /> Войти
                        </button>

                        {isShowLoginButtons && (
                            <div className="landing-header__auth-buttons-login">
                                <Link to="/psych/login">
                                    Вход для психолога <img src={arrowRight} alt="" />
                                </Link>

                                <Link to="/student/login">
                                    Вход для студента <img src={arrowRight} alt="" />
                                </Link>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {isShowMobileMenu ? (
                <img
                    onClick={hideMobileMenuHandler}
                    className="landing-header__menu-burger"
                    src={menuBurgerClose}
                    alt=""
                />
            ) : (
                <img
                    onClick={showMobileMenuHandler}
                    className="landing-header__menu-burger"
                    src={menuBurger}
                    alt=""
                />
            )}
        </header>
    );
});
